import CachedIcon from "@mui/icons-material/Cached";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { UploadThumbnail } from "@platformx/shared/static-assets";
import { DamContentGallery, usePostImageCrop } from "@platformx/x-image-render";
import { useEffect, useState } from "react";
import { fetchRequiredMessage, relativeImageURL } from "../../../utils/helperFns";
import HiddenTextBox from "../HiddenTextBox/HiddenTextBox";

const ImagePickerWithoutCrop = ({ field, errors, register, state, updateField }: any) => {
  const { cropRequest } = usePostImageCrop();
  const [galleryDialogOpen, setGalleryDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const toggleGallery = (toggleState: boolean, type: string) => {
    setGalleryDialogOpen(toggleState);
    if (type === "cancel") {
      //do something later
    }
  };

  const noCropCallBack = (data) => {
    const relativeUrl = `${data?.original_image_relative_path}.${data?.ext}`;
    setSelectedImage(relativeUrl);
    updateField(data, field.name, true);
    toggleGallery(false, "done");
  };

  const handleSelectedImage = async (image) => {
    try {
      const payload = {
        bitstreamId: image.bitStreamId,
        visibility: "public",
      };

      await cropRequest("no-crop", payload, noCropCallBack, image);
      toggleGallery(false, "");
    } catch (error: any) {
      const statusCode = error?.networkError?.statusCode || 0;
      toggleGallery(false, "");
      console.error("no crop api error", statusCode);
    }
  };

  const onUploadClick = () => {
    setGalleryDialogOpen(true);
  };

  useEffect(() => {
    if (
      state[field?.name]?.original_image &&
      Object.keys(state[field?.name]?.original_image).length > 0
    ) {
      const { original_image_relative_path = "", ext = "" } =
        state[field?.name]?.original_image || {};
      const relativeUrl = `${original_image_relative_path}.${ext}`;
      setSelectedImage(relativeUrl);
    }
  }, [state, field.name]);

  return (
    <div id={`${field?.name}_id`}>
      <Box
        sx={{
          backgroundColor: "#FFF",
        }}>
        {galleryDialogOpen && (
          <DamContentGallery
            handleImageSelected={handleSelectedImage}
            toggleGallery={toggleGallery}
            assetType={"Image"}
            processing={false}
            dialogOpen={galleryDialogOpen}
            isCrop={false}
          />
        )}
      </Box>
      {selectedImage ? (
        <Box
          sx={{
            position: "relative",
            borderRadius: "15px",
            minHeight: "192px",
            "& picture": {
              height: "192px",
            },
          }}
          mb={2}>
          <img
            style={{
              width: "100%",
              height: "192px",
              objectFit: "cover",
              display: "flex",
              borderRadius: "15px",
            }}
            src={relativeImageURL(selectedImage)}
            alt='socialshare'
          />
          <Box
            sx={{
              position: "absolute",
              top: "0",
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#7470708a",
              borderRadius: "15px",
            }}>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ cursor: "pointer" }} onClick={() => onUploadClick()}>
                <Box
                  sx={{
                    borderRadius: "50%",
                    backgroundColor: "#fff",
                    width: "25px",
                    height: "25px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "auto",
                  }}>
                  <CachedIcon sx={{ color: "#626060" }} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          className='x-image-render'
          data-testid='image-render'
          sx={{
            borderRadius: "15px",
            cursor: "pointer",
            height: "192px",
            backgroundColor: "#EFF0F6",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
          onClick={() => onUploadClick()}>
          <Box
            sx={{
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            m={1}>
            <img src={UploadThumbnail} alt='UploadIcon' />
          </Box>
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Typography variant='h5medium' color='textColorPrimary' data-testid='chooseimage'>
              Choose Your Image
            </Typography>
          </Box>
        </Box>
      )}
      <HiddenTextBox
        error={errors[field?.name]}
        value={state[field?.name]?.original_image?.original_image_relative_path}
        register={register}
        name={field?.name}
        required={fetchRequiredMessage(
          field?.validations,
          state[field?.name]?.original_image?.original_image_relative_path,
        )}
      />
    </div>
  );
};
export default ImagePickerWithoutCrop;
