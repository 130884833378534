import { ApolloError } from "@apollo/client";
import { LOGOUT_URL, PROJECT_CATCH_ERROR_LIST } from "@platformx/utilities";
import graphqlInstance from "../../config/graphqlConfig";
import axiosInstance from "../../config/restApiConfig";
import { AuthQueries } from "../../graphQL/queries/authQueries";

// List of trusted domains or paths
const allowedRedirects = [
  "dev.api.hcl-x.com",
  "hc-dev.api.hcl-x.com",
  "pp-api.hclhealthcare.in",
  "px-api.hclhealthcare.in",
  "staging-api-engagement.hclhealthcare.in",
  "staging-consoleapi.habithealth.com",
  "hc-qa.api.hcl-x.com",
  "marvericks.api.hcl-x.com",
  "api.hcltech-x.com",
  "qa.api.hcl-x.com",
];

function isTrustedDomain(url) {
  try {
    const parsedUrl = new URL(url);
    return allowedRedirects.includes(parsedUrl.hostname);
  } catch {
    return false; // Invalid URL
  }
}

const handleLogout = () => {
  try {
    if (isTrustedDomain(LOGOUT_URL)) {
      localStorage.removeItem("path");
      window.location.replace(LOGOUT_URL);
    } else {
      console.error("Redirection blocked: Untrusted URL detected!");
    }
  } catch (err) {
    return err;
  }
};
const authAPI = {
  verifySession: async (url: string) => {
    try {
      const res = await axiosInstance.get(process.env.NX_PUBLIC_API_URI + url, {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
        },
        withCredentials: true,
      });
      return res.data;
    } catch (err: any) {
      if (err?.response?.data?.code === 401 && !url.includes("verify")) {
        handleLogout();
      }
      return err;
    }
  },
  signIn: async (url: string, payload = {}) => {
    try {
      const res = await axiosInstance.post(process.env.NX_PUBLIC_API_URI + url, payload, {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
        },
        withCredentials: true,
      });
      return res.data.result ? res.data.result : res.data;
    } catch (err: any) {
      if (err?.response?.data?.code === 401) {
        handleLogout();
      }
      return err;
    }
  },
  fetchRoles: async (input: any): Promise<any> => {
    try {
      const { data } = await graphqlInstance.query({
        query: AuthQueries.FETCH_PERMISSIONS,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data?.authoring_permissionList || null;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:fetchRoles`, err);
      if (err instanceof ApolloError) throw err;
    }
  },
};
export default authAPI;
