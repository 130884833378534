import { Box, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { convertToLowerCase } from "../../utils/helperFns";
import useTextboxStyle from "./Textbox.styles";

interface TextBoxProps {
  name?: string;
  placeHolder?: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  maxCharLength?: any;
  state?: string;
  handleOnBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  error?: boolean;
  helperText?: string;
  borderColor?: string;
  isEmailExist?: boolean;
  isDisabled?: boolean;
  backgroundColor?: string;
  // New prop for type
  type?: string;
  restrictSpecialCharacters?: boolean;
}

const TextBox = ({
  name = "",
  placeHolder = "",
  handleChange,
  maxCharLength,
  state = "",
  handleOnBlur,
  error = false,
  helperText = "",
  backgroundColor,
  isDisabled = false,
  // Default value for type is 'textbox'
  type = "textbox",
  restrictSpecialCharacters = false,
}: TextBoxProps) => {
  const { t } = useTranslation();
  const classes = useTextboxStyle();
  const [restOfChar, setRestOfChar] = useState({
    lengthOfState: 0,
    restOfLength: 0,
    reachLimit: false,
  });
  const { restOfLength, reachLimit } = restOfChar;

  // Function to handle the remaining character length calculation
  const handleLength = (valueData = "") => {
    if (maxCharLength) {
      const lengthOfChar = convertToLowerCase(valueData).length;
      const rest = valueData ? maxCharLength - lengthOfChar : 0;

      setRestOfChar({
        restOfLength: rest,
        lengthOfState: lengthOfChar,
        reachLimit: Number(maxCharLength) === Number(lengthOfChar),
      });
    }
  };

  // Function to handle the change in input value
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;

    if (type === "numberbox" && value) {
      // Only allow positive numbers and enforce max length
      value = value.replace(/[^0-9]/g, "");
      if (maxCharLength && value.length > maxCharLength) {
        value = value.slice(0, maxCharLength);
      }
    } else if (restrictSpecialCharacters) {
      // Prevent special characters
      value = value.replace(/[^a-zA-Z0-9 ]/g, "");
    }

    if (handleChange) {
      event.target.value = value;
      handleChange(event);
    }
    handleLength(value);
  };

  // Effect to handle setting the initial state value
  useEffect(() => {
    if (state) {
      const inputElement: any = document.querySelector(`#${name}`);
      if (inputElement) {
        inputElement.value = state;
        handleLength(state);
      }
    }
  }, [state, name]);

  // Function to handle keydown event
  const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "F") {
      e.stopPropagation();
    }
  };

  return (
    <Box mr={2} className='noMarginRight'>
      <TextField
        variant='filled'
        size='small'
        name={name}
        placeholder={placeHolder}
        fullWidth
        id={name}
        error={error}
        helperText={helperText}
        disabled={isDisabled}
        type={type === "numberbox" ? "number" : "text"} // Set the type of the TextField
        sx={{
          input: { cursor: isDisabled ? "not-allowed" : undefined },
          backgroundColor: backgroundColor,
        }}
        inputProps={{
          maxLength: type === "textbox" ? maxCharLength : undefined, // Only apply maxLength for text input
          readOnly: false,
        }}
        onChange={onChange}
        onBlur={handleOnBlur}
        onKeyDown={handleKeydown}
        FormHelperTextProps={{ className: classes.typotool }}
      />

      {maxCharLength && (
        <Typography variant='p4regular' sx={{ marginTop: "10px" }}>
          {reachLimit ? (
            <>0 {`${t("characters")} ${t("left")}`}</>
          ) : (
            <>
              {restOfLength
                ? `${restOfLength} ${t("characters")} ${t("left")}`
                : `${maxCharLength} ${t("characters")} ${t("max")}`}
            </>
          )}
        </Typography>
      )}
    </Box>
  );
};

export default TextBox;
