import { LOGOUT_URL, getSelectedSite, getSubDomain } from "@platformx/utilities";
import secureLocalStorage from "react-secure-storage";
import axiosInstance from "./restApiConfig";

// List of trusted domains or paths
const allowedRedirects = [
  "dev.api.hcl-x.com",
  "hc-dev.api.hcl-x.com",
  "pp-api.hclhealthcare.in",
  "px-api.hclhealthcare.in",
  "staging-api-engagement.hclhealthcare.in",
  "staging-consoleapi.habithealth.com",
  "hc-qa.api.hcl-x.com",
  "marvericks.api.hcl-x.com",
  "api.hcltech-x.com",
  "qa.api.hcl-x.com",
];

function isTrustedDomain(url) {
  try {
    const parsedUrl = new URL(url);
    return allowedRedirects.includes(parsedUrl.hostname);
  } catch {
    return false; // Invalid URL
  }
}

const handleLogout = () => {
  try {
    if (isTrustedDomain(LOGOUT_URL)) {
      localStorage.removeItem("userSession");
      localStorage.removeItem("selectedSite");
      secureLocalStorage.removeItem("imageUuid");
      secureLocalStorage.removeItem("videoUuid");
      window.location.replace(LOGOUT_URL);
    } else {
      console.error("Redirection blocked: Untrusted URL detected!");
    }
  } catch (err) {
    return err;
  }
};

export const getRequest = async (url) => {
  try {
    const res = await axiosInstance.get(process.env.NX_PUBLIC_API_URI + url, {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        sitename: getSelectedSite(),
        site_host: getSubDomain(),
      },
      withCredentials: true,
    });
    return res?.data?.result ? res?.data?.result : res?.data;
  } catch (err: any) {
    if (err?.response?.data?.code === 401 && !url.includes("verify")) {
      handleLogout();
    }
    return err;
  }
};
export const getRequestFromDelivery = async (url) => {
  try {
    const res = await axiosInstance.get(process.env.NX_PUBLIC_DELIVERY_URI + url, {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        sitename: getSelectedSite(),
        site_host: getSubDomain(),
      },
      withCredentials: true,
    });
    return res?.data?.result ? res?.data?.result : res?.data;
  } catch (err: any) {
    if (err?.response?.data?.code === 401 && !url.includes("verify")) {
      handleLogout();
    }
    return err;
  }
};

export const putRequest = async (url, payload) => {
  try {
    const res = await axiosInstance.put(process.env.NX_PUBLIC_API_URI + url, payload, {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        sitename: getSelectedSite(),
        site_host: getSubDomain(),
      },
      withCredentials: true,
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err: any) {
    if (err?.response?.data?.code === 401) {
      handleLogout();
    }
    return err;
  }
};

export const postRequest = async (url, payload = {}) => {
  try {
    const res = await axiosInstance.post(process.env.NX_PUBLIC_API_URI + url, payload, {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        sitename: getSelectedSite(),
        site_host: getSubDomain(),
      },
      withCredentials: true,
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err: any) {
    if (err?.response?.data?.code === 401) {
      handleLogout();
    }
    return err;
  }
};

export const commonPostApiCall = (url, payload = {}) => {
  try {
    return axiosInstance.post(url, payload, {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        sitename: getSelectedSite(),
        site_host: getSubDomain(),
      },
    });
  } catch (err: any) {
    return err.response;
  }
};

export const commonPutApiCall = (url, payload = {}) => {
  try {
    return axiosInstance.put(url, payload, {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        sitename: getSelectedSite(),
        site_host: getSubDomain(),
      },
    });
  } catch (err: any) {
    return err.response;
  }
};
