export const hasOwnProp = (obj: object, key: string): boolean => {
  return Object.prototype.hasOwnProperty.call(obj, key);
};
export function isEmpty(obj) {
  return Object.keys(obj).length > 0;
}
export const formatPageUrl = (url) => {
  let tmp = url?.toLowerCase();
  tmp = tmp.replace(/\s/g, "");
  tmp = tmp.replace(/[^a-z0-9\- ]/gi, "");
  return tmp;
};

export const createSession = (userSession, isActive, role: string) => {
  return {
    isActive: isActive || false,
    role: role,
    permissions: userSession?.permissions,
    userInfo: userSession,
  };
};

export const getStyleString = (styles) =>
  Object.entries(styles)
    .map(([prop, value]) => `${prop}: ${value}`)
    .join("; ");

/**
 * string to parse convert
 * @param urijson
 * @returns object
 */
export const uriToJSON = (urijson) => {
  if (urijson) {
    return JSON.parse(urijson);
  }
  return {};
};

export const removeSearchLocalStorage = () => {
  localStorage.removeItem("contentType");
  localStorage.removeItem("searchKeyword");
  localStorage.removeItem("searchTags");
  localStorage.removeItem("author");
};

export const convertToSnakeCase = (inputString = "") => {
  let capitalCount = 0;
  const snakeCaseString = inputString.replace(/[A-Z]/g, (match) => {
    capitalCount++;
    if (capitalCount > 1) {
      return "_" + match;
    }
    return match;
  });
  return snakeCaseString?.toLowerCase();
};

export const getIconWithTitle = (contentList, contentType: string) => {
  const filteredData = contentList.find(
    (content) =>
      content?.isGallery && content?.id?.toLocaleLowerCase() === contentType?.toLocaleLowerCase(),
  );
  return {
    title: filteredData?.title || "",
    icon: filteredData?.icon || "",
    id: filteredData?.id || "",
  };
};

export const getIconWithTitleForSearch = (contentList, contentType: string) => {
  const filteredData = contentList?.find(
    (content) =>
      content?.hasSearch && content?.id?.toLocaleLowerCase() === contentType?.toLocaleLowerCase(),
  );
  return {
    title: filteredData?.title || "",
    icon: filteredData?.icon || "",
    id: filteredData?.id || "",
    path: filteredData?.url || "",
  };
};

export const getBucketName = (bucketNameProp = "") => {
  let bucketName = bucketNameProp;
  if (!bucketNameProp) {
    bucketName = process.env.NX_PUBLIC_BUCKET_NAME ?? "NA";
  }
  return bucketName !== "NA" ? bucketName + "/" : "";
};
