import { getRestApiCall, postRestApiCall } from "@platformx/utilities";

/**
 * courseId based get course fill details
 * post call
 */
export const getCourseDetailsApiCall = (courseId: string, secondaryArgs: any) => {
  const {
    prelemBaseEndpoint: {
      deliveryEndPoint = "https://marvericks.delivery.hcl-x.com/platform-x/",
      language = "en",
    } = {},
    sitename,
  } = secondaryArgs;
  return getRestApiCall(
    `${deliveryEndPoint}api/v1/web/en/delivery/course-model?path=${courseId}`,
    language,
    sitename,
  );
  // return getRestApiCall(
  //   `https://marvericks.delivery.hcl-x.com/platform-x/api/v1/web/en/delivery/course-model?path=108058619401306`
  // );
};

/**
 * courseId based get course fill details
 * post call
 */
export const getLearningListApiCall = (ele: any) => {
  const { secondaryArgs = {}, userId = "" } = ele;
  const {
    prelemBaseEndpoint: {
      // deliveryEndPoint = "https://dev.users.hcl-x.com/platform-x/user-service/",
      language = "en",
    } = {},
    sitename,
  } = secondaryArgs;

  const data = JSON.stringify({
    query: `query { userCoursesList(user_id:${JSON.stringify(userId)}) {
      user_id 
      title 
      description 
      author 
      lessons 
      teaser 
      teaser_image 
      course_id 
      course_url 
      status
      created_at
      updated_at
    } } `,
  });
  return postRestApiCall(
    `https://dev.users.hcl-x.com/platform-x/user-service/`,
    data,
    language,
    sitename,
  );
};
export const getUserInfo = (key) => {
  const userLoginDetails = localStorage.getItem("userLoginDetails");
  if (userLoginDetails) {
    const useDeatils = JSON.parse(userLoginDetails);
    return useDeatils?.data?.[key];
  } else {
    return null;
  }
};

export const parseSelectedObject = (selectedPersonalisation) => {
  if (selectedPersonalisation && typeof selectedPersonalisation === "string") {
    try {
      let parseSelectedPersonalisation;
      // Try parsing once for selected Personalisation
      parseSelectedPersonalisation = JSON.parse(selectedPersonalisation);
      // If still a string, attempt to parse again
      if (parseSelectedPersonalisation && typeof parseSelectedPersonalisation === "string") {
        parseSelectedPersonalisation = JSON.parse(parseSelectedPersonalisation);
      }
      return parseSelectedPersonalisation;
    } catch (e) {
      console.error(e);
    }
  }
  return selectedPersonalisation;
};
export const getBucketName = (bucketNameProp = "") => {
  let bucketName = bucketNameProp;
  if (!bucketNameProp) {
    bucketName = process.env.NX_PUBLIC_BUCKET_NAME ?? "NA";
  }
  return bucketName !== "NA" ? bucketName + "/" : "";
};

export const getDefaultCroppedImage = (
  secondaryArgs: any,
  publishedImages: [],
  originalImage: any,
  defaultRatio = "landscape",
) => {
  const landscapeImg = publishedImages.find(
    ({ aspect_ratio }: any) => aspect_ratio === defaultRatio,
  );
  const { folder_path: imgUrl = "" } = landscapeImg || { folder_path: "" };
  const { gcpUrl, bucketName } = secondaryArgs;
  return `url('${gcpUrl}/${getBucketName(bucketName)}${imgUrl}.webp'), url('${gcpUrl}/${getBucketName(bucketName)}${imgUrl}.${originalImage.ext}'), rgba(0, 0, 0, 0.6)`;
};
