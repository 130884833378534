import { format } from "date-fns";
import { getBucketName } from "../../../../../utils/Helper";

export const timeSince = (publishDate: any) => {
  if (publishDate) {
    const date = new Date(publishDate);
    const curDate = new Date();
    const seconds = Math.floor((curDate.getTime() - date.getTime()) / 1000);
    let interval = seconds / 31536000;
    if (interval > 1) {
      return `${Math.floor(interval)}y ago`;
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return `${Math.floor(interval)}mo ago`;
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return `${Math.floor(interval)}d ago`;
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return format(new Date(date), "H:mm");
    }
    interval = seconds / 60;
    if (interval > 1) {
      return format(new Date(date), "H:mm");
    }
    return `${Math.floor(seconds) >= 0 ? Math.floor(seconds) : 0}s ago`;
  }
  return "";
};

export const updatedBlogToSend = (blogData: any = {}) => {
  const descriptionData = !blogData?.BlogAuthorName
    ? `<span style="word-wrap: break-word" class="onlydesc">${
        blogData?.BlogTextArea + blogData.mediaUrl
      } </span>`
    : `<span style="word-wrap: break-word" class="onlydesc">${blogData?.BlogTextArea} </span>`;
  return {
    title: blogData?.BlogTitle,
    description: descriptionData,
    is_published: true,
    is_soft_delete: false,
    created_date: blogData.savedBlogData?.created_date,
    created_by: blogData.savedBlogData?.created_by,
    id: blogData?.savedBlogId,
    cta_url: blogData?.cta_url,
    cta_title: blogData?.cta_title,
    content_type: "Blog",
    event_path: blogData.eventPath,
    page: blogData.eventPath,
    assets: blogData.assetstosend,
    item_path: blogData.contentTypeData,
    embeded: [{ code: blogData?.BlogEmbed }],
    authors: blogData?.BlogAuthorName ? [blogData?.BlogAuthorName] : [],
    key_highlighter: [
      {
        highlighter: blogData?.BlogKeyHighlighter,
        time: blogData?.BlogTimeStamp,
      },
    ],
    last_published_date: new Date(),
    last_published_by: blogData.username,
    modified_date: new Date(),
    modified_by: blogData.username,
  };
};

/**
 * fallBack image
 */
export const defaultFallBackImage = () => {
  const gcpUrl = process.env.NX_PUBLIC_GCP_URL;
  const defaultImage = process.env.NX_PUBLIC_DEFAULT_IMAGE;
  return `${gcpUrl}/${getBucketName()}${defaultImage}`;
};
